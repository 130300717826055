<template>
  <div class="myAssAppeal">
    <!-- 我的考核结果 -->
    <van-nav-bar :title="title"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true" />
    <div class="content">
      <div class="app_details">
        <van-field class="empcode"
                   :label="$t('jxInterviewDetails.itdTxt1')"
                   :value="appDetails.empcode"
                   readonly />
        <van-field :label="$t('jxInterviewDetails.itdTxt2')"
                   :value="appDetails.empname"
                   readonly />
        <van-field :label="$t('jxInterviewDetails.itdTxt3')"
                   :value="appDetails.khtimename"
                   readonly />
        <van-field :label="$t('jxInterviewDetails.itdTxt4')"
                   :value="appDetails.planname"
                   readonly />
        <van-field :label="$t('jxInterviewDetails.itdTxt5')"
                   :value="appDetails.lastscore"
                   readonly />
        <van-field :label="$t('jxInterviewDetails.itdTxt6')"
                   :value="appDetails.khres"
                   readonly />
        <div class="presonAvar">
          <img style="border-radius:100%;"
               :src="appDetails.picurl" />
        </div>
      </div>
      <!-- 我的面谈--业绩说明、能力说明 -->
      <div v-if="isMyView == 'true'"
           class="app_reason">
        <div class="text1">{{$t('jxInterviewDetails.itdTxt7')}}</div>
        <div class="thrid_desc">
          <van-field class="brief"
                     v-model="yjremark"
                     rows="5"
                     autosize
                     label
                     type="textarea"
                     show-word-limit
                     style="margin-bottom: 15px" />
        </div>
        <div class="text1">{{$t('jxInterviewDetails.itdTxt8')}}</div>
        <div class="thrid_desc">
          <van-field class="brief"
                     v-model="nlremark"
                     rows="5"
                     autosize
                     label
                     type="textarea"
                     show-word-limit
                     style="margin-bottom: 15px" />
        </div>
      </div>
      <!-- 下级面谈--业绩说明、能力说明 -->
      <div v-else
           class="app_reason">
        <div class="text2">{{$t('jxInterviewDetails.itdTxt9')}}</div>
        <div class="thrid_desc">
          <van-field class="brief"
                     v-model="appDetails.yjremark"
                     rows="5"
                     autosize
                     readonly
                     label
                     type="textarea"
                     show-word-limit
                     style="margin-bottom: 15px" />
        </div>
        <div class="text2">{{$t('jxInterviewDetails.itdTxt10')}}</div>
        <div class="thrid_desc">
          <van-field class="brief"
                     v-model="appDetails.nlremark"
                     rows="5"
                     autosize
                     readonly
                     label
                     type="textarea"
                     show-word-limit
                     style="margin-bottom: 15px" />
        </div>
      </div>
      <!-- 我的绩效面谈--评价 -->
      <div v-if="isMyView == 'true'"
           class="app_desc">
        <div class="text2">{{$t('jxInterviewDetails.itdTxt11')}}</div>
        <div class="thrid_desc">
          <van-field class="brief"
                     v-model="appDetails.ivres"
                     rows="5"
                     autosize
                     readonly
                     label
                     type="textarea"
                     show-word-limit
                     style="margin-bottom: 15px" />
        </div>
      </div>
      <!-- 下级面谈--评价 -->
      <div v-else
           class="app_desc">
        <div class="text1">{{$t('jxInterviewDetails.itdTxt12')}}</div>
        <div class="thrid_desc">
          <van-field class="brief"
                     v-model="ivres"
                     rows="5"
                     autosize
                     label
                     type="textarea"
                     show-word-limit
                     style="margin-bottom: 15px" />
        </div>
         
      </div>
      <div  class="app_desc">
      <div class="text1">{{$t('jxInterviewDetails.itdTxt20')}}</div>
        <div class="thrid_desc">
          <van-field class="brief"
                     v-model="c1"
                     rows="5"
                     autosize
                     label
                     type="textarea"
                     show-word-limit
                     style="margin-bottom: 15px" />
        </div>
         <div class="text1">{{$t('jxInterviewDetails.itdTxt21')}}</div>
        <div class="thrid_desc">
          <van-field class="brief"
                     v-model="c2"
                     rows="5"
                     autosize
                     label
                     type="textarea"
                     show-word-limit
                     style="margin-bottom: 15px" />
        </div>
         <div class="text1">{{$t('jxInterviewDetails.itdTxt22')}}</div>
        <div class="thrid_desc">
          <van-field class="brief"
                     v-model="c3"
                     rows="5"
                     autosize
                     label
                     type="textarea"
                     show-word-limit
                     style="margin-bottom: 15px" />
        </div>
         <div class="text1">{{$t('jxInterviewDetails.itdTxt23')}}</div>
        <div class="thrid_desc">
          <van-field class="brief"
                     v-model="c4"
                     rows="5"
                     autosize
                     label
                     type="textarea"
                     show-word-limit
                     style="margin-bottom: 15px" />
        </div>
         <div class="text1">{{$t('jxInterviewDetails.itdTxt24')}}</div>
        <div class="thrid_desc">
          <van-field class="brief"
                     v-model="c5"
                     rows="5"
                     autosize
                     label
                     type="textarea"
                     show-word-limit
                     style="margin-bottom: 15px" />
        </div>
         <div class="text1">{{$t('jxInterviewDetails.itdTxt25')}}</div>
        <div class="thrid_desc">
          <van-field class="brief"
                     v-model="c6"
                     rows="5"
                     autosize
                     label
                     type="textarea"
                     show-word-limit
                     style="margin-bottom: 15px" />
        </div>
         <div class="text1">{{$t('jxInterviewDetails.itdTxt26')}}</div>
        <div class="thrid_desc">
          <van-field class="brief"
                     v-model="c7"
                     rows="5"
                     autosize
                     label
                     type="textarea"
                     show-word-limit
                     style="margin-bottom: 15px" />
        </div>
         <div class="text1">{{$t('jxInterviewDetails.itdTxt27')}}</div>
        <div class="thrid_desc">
          <van-field class="brief"
                     v-model="c8"
                     rows="5"
                     autosize
                     label
                     type="textarea"
                     show-word-limit
                     style="margin-bottom: 15px" />
        </div>
         <div class="text1">{{$t('jxInterviewDetails.itdTxt28')}}</div>
        <div class="thrid_desc">
          <van-field class="brief"
                     v-model="c9"
                     rows="5"
                     autosize
                     label
                     type="textarea"
                     show-word-limit
                     style="margin-bottom: 15px" />
        </div>
        </div>
      <div v-if="isMyView == 'false'"
           class="app_button2">
        <!-- <van-button type="info"
                    color="#ef2e45"
                    size="small"
                    @click="operate(false)">{{$t('jxInterviewDetails.itdTxt13')}}</van-button> -->
        <van-button type="info"
                    block
                    size="small"
                    :disabled="!xjDisabled"
                    @click="operate(true)">{{$t('jxInterviewDetails.itdTxt15')}}</van-button>

      </div>
      <div v-else
           class="app_button2">
        <van-button type="info"
                    block
                    :disabled="!myDisabled"
                    @click="submitMyInterview">{{$t('jxInterviewDetails.itdTxt15')}}</van-button>

      </div>
    </div>

    <!-- 不同意面试弹窗 -->
    <van-dialog v-model="showBTYDia"
                :title="$t('jxInterviewDetails.itdTxt13')"
                show-cancel-button
                :before-close="closeBTYDia">
      <div style="padding:10px">
        <van-cell class="desc_cell"
                  required
                  :value="$t('jxInterviewDetails.itdTxt16')" />
        <van-field class="desc_field"
                   v-model="fjreason"
                   rows="3"
                   autosize
                   type="textarea"
                   :placeholder="$t('jxInterviewDetails.itdTxt16')" />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant'
import { getMtDetail, postXjSsInfo, postMyMtDetail, postXjMtDetail } from '@api/wxjx.js'
export default {
  data () {
    const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
    return {
      userInfo,
      title: this.$route.query.title,
      isMyView: this.$route.query.isMyView,
      mautoid: this.$route.query.mautoid,
      empcode: this.$route.query.empcode,
      appDetails: {},
      yjremark: '', // 业绩说明
      nlremark: '', // 能力说明
      ivres: "", // 面谈评价
      fjreason: null, // 否决原因
      c1: '', 
      c2: '', 
      c3: '', 
      c4: '', 
      c5: '', 
      c6: '', 
      c7: '', 
      c8: '', 
      c9: '', 
      showBTYDia: false,
    }
  },
  computed: {
    myDisabled () {
      return this.yjremark && this.nlremark
    },
    xjDisabled () {
      return this.ivres
    }
  },
  created () {
    this.getInterviewDetail()
  },
  methods: {
    onClickLeft () {
      this.$router.push({ path: '/jxPerformanceInterview', query: { isMyView: this.isMyView } })
    },
    // 查详情
    getInterviewDetail () {
      getMtDetail({
        mautoid: this.mautoid,
        username: this.empcode
      }).then(res => {
        this.appDetails = res.data.length > 0 ? res.data[0] : {}
        this.c1 = res.data.length > 0 ? res.data[0].c1 : ''
        this.c2 = res.data.length > 0 ? res.data[0].c2 : ''
        this.c3 = res.data.length > 0 ? res.data[0].c3 : ''
        this.c4 = res.data.length > 0 ? res.data[0].c4 : ''
        this.c5 = res.data.length > 0 ? res.data[0].c5 : ''
        this.c6 = res.data.length > 0 ? res.data[0].c6 : ''
        this.c7 = res.data.length > 0 ? res.data[0].c7 : ''
        this.c8 = res.data.length > 0 ? res.data[0].c8 : ''
        this.c9 = res.data.length > 0 ? res.data[0].c9 : ''
      })
    },
    // 否决/同意按钮
    operate (val) {
      // 清空原因
      this.fjreason = null
      if (val)
      {
        // 同意
        Dialog.confirm({
          message: this.$t('jxInterviewDetails.itdTxt17'),
        })
          .then(() => {
            // on confirm
            this.submit(true)
          })
          .catch(() => {
            // on cancel
          });
      } else
      {
        // 否决
        this.showBTYDia = true
      }
    },
    // 不同意弹窗回调
    closeBTYDia (action, done) {
      if (action == 'confirm')
      {
        if (this.fjreason)
        {
          this.submit(false)
          done()
        } else
        {
          Toast.fail(this.$t('jxInterviewDetails.itdTxt18'))
          done(false)
        }
      } else
      {
        done()
      }
    },
    // 同意下级的绩效面谈
    submit (check) {
      postXjMtDetail({
        mautoid: this.mautoid,
        ischeck: check,
        ivres: this.ivres,
        fjreason: this.fjreason,
        username: this.empcode,
        c1:this.c1,
        c2:this.c2,
        c3:this.c3,
        c4:this.c4,
        c5:this.c5,
        c6:this.c6,
        c7:this.c7,
        c8:this.c8,
        c9:this.c9
      }).then(res => {
        if (res.data[0].info)
        {
          Toast.success(res.data[0].info)
        } else
        {
          Toast.success(this.$t('jxInterviewDetails.itdTxt19'))
          this.onClickLeft()
        }
      })
    },
    // 提交 我的绩效面谈
    submitMyInterview () {
      postMyMtDetail({
        mautoid: this.mautoid,
        yjremark: this.yjremark,
        nlremark: this.nlremark,
        username: this.empcode
      }).then(res => {
        if (res.data[0].info)
        {
          Toast.success(res.data[0].info)
        } else
        {
          Toast.success(this.$t('jxInterviewDetails.itdTxt19'))
          this.onClickLeft()
        }
      })
    }

  }
}
</script>

<style lang="less" scoped>
.myAssAppeal {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 90px);
    .app_details {
      position: relative;
      /deep/.van-cell {
        .van-cell__title {
          color: #ababab;
        }
        .van-cell__value {
          .van-field__body {
            .van-field__control {
              color: #ababab;
            }
          }
        }
      }
      /deep/.empcode {
        .van-cell__title {
          color: #ababab;
        }
        .van-cell__value {
          .van-field__body {
            .van-field__control {
              color: #2b8df0 !important;
            }
          }
        }
      }
      .van-cell::after {
        border-bottom: 0;
      }
      .presonAvar {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 122px;
        min-width: 122px;
        height: 122px;
        border-radius: 122px;
        border: 2px solid #2b8df0;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .app_reason {
      margin: 30px 0;
      padding: 30px;
      background: #fff;
      .text1 {
        font-size: 28px;
        color: #ef2e45;
      }
      .text2 {
        font-size: 28px;
        color: #2b8df0;
      }
      .reqkhres {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
        border-radius: 12px;
        padding: 4px;
        margin-bottom: 30px;
        .van-cell::after {
          border-bottom: 0;
        }
      }
      .thrid_desc {
        margin: 16px 4px 4px 4px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
        border-radius: 12px;
        .brief {
          border-radius: 12px;
        }
      }
    }
    .app_desc {
      margin: 30px 0;
      padding: 30px;
      background: #fff;
      .text1 {
        font-size: 28px;
        color: #ef2e45;
      }
      .text2 {
        font-size: 28px;
        color: #2b8df0;
      }
      .thrid_desc {
        margin: 16px 4px 4px 4px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
        border-radius: 12px;
        .brief {
          border-radius: 12px;
        }
      }
    }
    .app_button1 {
      background: #fff;
      padding: 20px 60px;
      display: flex;
      justify-content: space-around;
      .van-button {
        border-radius: 20px;
        width: 40%;
      }
    }
    .app_button2 {
      background: #fff;
      padding: 20px 100px;
      .van-button {
        border-radius: 20px;
      }
    }
  }
}
</style>